<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <div class='float-right position-relative'>
        <p class="text-left pb-0 mb-0 d-none d-md-block ml-2 mr-5 font-weight-bold h5">{{account_name}}</p>
        <i class="d-none d-md-block fa fa-chevron-down dropdown-icon"></i>
        <p class="d-none d-md-block mb-0 pb-0 ml-2 mr-5 text-left">(Admin)</p>
      </div>
      <div class='float-right mr-2'><img
        src="img/avatars/6.jpg"
        class="img-avatar"
      /></div>
    </template>

    <template slot="dropdown">
      <b-dropdown-item :to="{ path: '/admin/profile' }"><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item :to="{ path: '/admin/password' }"><i class="fa fa-lock" /> Password</b-dropdown-item>
      <b-dropdown-item v-on:click="logout()"><i class="fa fa-sign-out" /> Logout</b-dropdown-item>
    </template>

  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'AdminDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      itemsCount: 42,
      changePassword: false,
      account_name: "",
      account_type: "",
    }
  },
  beforeCreate() {
    if(this.$session.get('user') !== 'admin'){
      this.$session.destroy();
      this.flash({ message: 'UNAUTHORIZED', variant: 'danger' });
      this.$router.push('/');
    }
  },
  mounted() {
    this.$http.get('/admin/get/profile', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.$session.get('jwt')
      }
    })
    .then(response => {
      if (response.status === 200) {
        this.account_name = response.data.result.fname + " " + response.data.result.lname;
        this.account_type = this.$session.get('user');
      }
    })
    .catch(error => {

    })
  },
  methods: {
    logout: function () {
      this.$session.destroy()
      this.flash({ message: 'Logout Successful', variant: 'success' });
      this.$router.push('/')
    }
  }
}
</script>
